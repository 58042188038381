<template>
  <div 
    class="buttons are-medium"
  >
    <button 
      v-for="b in data"
      :key="b.label"
      :class="getClass(selected, b.value)"
      @click="button_action(b.action, b.value)"
    >
      {{b.label}}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
  },
  methods: {
    getClass(selected, value) {
      if (selected == value) {
        return "button is-link"
      } else {
        return "button"
      }
    },
    button_action(action, value) {
      if (action == "cloudinary") {
        action_cloudinary()
      } 
      else if (action == "submit") {
        this.$parent.onSubmit(value)
      }
      //next
      else {
        this.$router.push(value)
      }
    }
  }
};


function action_cloudinary() {
  console.log("cloudinary")
}
</script>


