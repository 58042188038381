<template>
  <OnboardPage 
    :content="content"
  >  
    <Buttons 
      :data="content.buttons" 
      :selected="deal.category"
    /> 
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import Buttons from "@/components/Buttons.vue";

export default {
  components: {
    OnboardPage,
    Buttons
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      deal: {category: ''},
      content: {
        page: 'organization-deal-category',
        step: 3,
        title: 'Pick a deal category',
        subtitle: 'Choose a category to move forward',
        sideComponentText: 'Attract more customers. Only pay when a customer purchases and claims the deal.',
        nextPage: "organization-deal-spend",
        buttons: [
          {
            class: "button",
            label: "Thing to do",
            placeholder: "",
            value: "todo",
            action: "submit"
          },
          {
            class: "button",
            label: "Shop",
            placeholder: "",
            value: "shop",
            action: "submit"
          },
          {
            class: "button",
            label: "Eat and drink",
            placeholder: "",
            value: "eat",
            action: "submit"
          },
          {
            class: "button",
            label: "Service",
            placeholder: "",
            value: "services",
            action: "submit"
          },
          {
            class: "button",
            label: "Stay",
            placeholder: "",
            value: "stay",
            action: "submit"
          },
        ],
        showBack: true
      }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
  },
  mounted() {
    if (this.currentBusiness && 
        this.currentBusiness.slug && 
        this.currentDeal &&
        this.currentDeal.slug
      ) {

      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.deal = this.currentDeal
        this.business = this.currentBusiness
      }  

    } else if (this.currentBusiness && 
      this.currentBusiness.slug) {
 
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.business = this.currentBusiness
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit(category) {
      this.submitting = true;
      
      let params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        deal: {
          category: category
        }
      }
      
      if (this.currentDeal != undefined && this.currentDeal.slug != undefined) {
        params["slug"] = this.currentDeal.slug
        this.$store.dispatch("updateDeal", params)
          .then(() => {
            this.$router.push('/o/' + this.content.nextPage)
          })
          .catch(error => {
            if (error instanceof AuthError || error instanceof NetworkError) {
              throw error
            } else {
              console.log(error)
              this.error = error
            }
          })
          .finally(() => {
            this.submitting = false
          })
      } else {
        this.$store.dispatch("createDeal", params)
          .then(() => {
            this.$router.push('/o/' + this.content.nextPage)
          })
          .catch(error => {
            if (error instanceof AuthError || error instanceof NetworkError) {
              throw error
            } else {
              console.log(error)
              this.error = error
            }
          })
          .finally(() => {
            this.submitting = false
          })
      }

    }
  }
}

</script>
